:root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-display: block;
  flex-direction: column;
  font-family: Inter, Roboto, Helvetica Neue, Arial Nova, Nimbus Sans, Arial, sans-serif;
  overflow: clip visible;
}

html {
  font-size: 18px;
}

body {
  letter-spacing: .02rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
}

h1 {
  letter-spacing: .05rem;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  letter-spacing: .05rem;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;
}

h3 {
  letter-spacing: .05rem;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.2;
}

h4 {
  letter-spacing: .05rem;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.2;
}

h5 {
  letter-spacing: .05rem;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2;
}

h6 {
  letter-spacing: .05rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2;
}

p {
  letter-spacing: .02rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
}

a {
  letter-spacing: .05rem;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-in-from-black {
  from {
    background-color: #000;
  }

  to {
    background-color: #0009;
  }
}

@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes wobble {
  0%, 100% {
    transform-origin: 50%;
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-32px)rotate(-5deg);
  }

  30% {
    transform: translateX(16px)rotate(5deg);
  }

  45% {
    transform: translateX(-16px)rotate(-2.77778deg);
  }

  60% {
    transform: translateX(9.69697px)rotate(1.66667deg);
  }

  75% {
    transform: translateX(-5.81818px)rotate(-1deg);
  }
}

.vh-25 {
  height: 25vh;
}

.vh-50 {
  height: 50vh;
}

.vh-75 {
  height: 75vh;
}

.border-dashed {
  border-style: dashed !important;
}

.color-nav {
  background-color: #0f0f0f;
  height: 5rem;
  padding-top: 1px;
  transition: all .7s;
}

.footer-category {
  color: #fff;
  letter-spacing: .1rem;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.nav-link {
  color: #d9d9d9;
  letter-spacing: .1rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.nav-link:hover {
  color: #fa3;
}

.btn-primary {
  --bs-btn-color: #f5f5f5;
  --bs-btn-bg: #ff7f00;
  --bs-btn-border-color: #ff7f00;
  --bs-btn-hover-color: #f5f5f5;
  --bs-btn-hover-bg: #fa3;
  --bs-btn-hover-border-color: #fa3;
}

.btn-outline-primary {
  --bs-btn-color: #f5f5f5;
  --bs-btn-border-color: #ff7f00;
  --bs-btn-hover-color: #f5f5f5;
  --bs-btn-hover-bg: #fa3;
  --bs-btn-hover-border-color: #fa3;
}

/*# sourceMappingURL=styles.88cc298a.css.map */
