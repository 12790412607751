:root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', Arial, sans-serif;

    flex-direction: column;
    font-display: block;

    overflow-x: clip;
    overflow-y: visible;
}


/*
h2, h3, h4, h5, h6 {
    margin-bottom: 1.5rem;
}

h1 {
    font-size: clamp(2rem, 1.7692rem + 1.0256vw, 3rem);
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.02em;
}

h2 {
    font-size: clamp(1.6rem, 1.3923rem + 0.9231vw, 2.5rem);
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0.01em;
}

h3 {
    font-size: clamp(1.4rem, 1.2615rem + 0.6154vw, 2rem);
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0.01em;
}

h4 {
    font-size: clamp(1.2rem, 1.1308rem + 0.3077vw, 1.5rem);
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0;
}

h5 {
    font-size: clamp(1rem, 0.9423rem + 0.2564vw, 1.25rem);
    font-weight: 600;
    line-height: 1.6;
    letter-spacing: 0;
}

h6 {
    font-size: clamp(0.8rem, 0.7rem + 0.2051vw, 1rem);
    font-weight: 600;
    line-height: 1.6;
}

ul {
    list-style: disc;
    margin-bottom: 1.5em;
}

li {
    font-size: clamp(1rem, 0.9423rem + 0.2564vw, 1.25rem);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.6;
}

p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0;
} */



html {
    font-size: 18px;
  }

  body {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.02rem;
  }

  h1 {
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.05rem;
  }

  h2 {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.05rem;
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.05rem;
  }

  h4 {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.05rem;
  }

  h5 {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.05rem;
  }

  h6 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.05rem;
  }

  p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.02rem;
  }

  a {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.05rem;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }












@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-in-from-black {
    from {
        background-color: rgba(0, 0, 0, 1);
    }

    to {
        background-color: rgba(0, 0, 0, .6);
    }
}

@keyframes fade-up {
    from {
        opacity: 0;
        -webkit-transform: translateY(1rem);
        transform: translateY(1rem);
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes wobble {

    0%,
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    15% {
        -webkit-transform: translateX(-32px) rotate(-5deg);
        transform: translateX(-32px) rotate(-5deg);
    }

    30% {
        -webkit-transform: translateX(calc(32px / 2)) rotate(5deg);
        transform: translateX(calc(32px / 2)) rotate(5deg);
    }

    45% {
        -webkit-transform: translateX(calc(-32px / 2)) rotate(calc(-5deg / 1.8));
        transform: translateX(calc(-32px / 2)) rotate(calc(-5deg / 1.8));
    }

    60% {
        -webkit-transform: translateX(calc(32px / 3.3)) rotate(calc(5deg / 3));
        transform: translateX(calc(32px / 3.3)) rotate(calc(5deg / 3));
    }

    75% {
        -webkit-transform: translateX(calc(-32px / 5.5)) rotate(calc(-5deg / 5));
        transform: translateX(calc(-32px / 5.5)) rotate(calc(-5deg / 5));
    }
}

.vh-25 {
    height: 25vh;
}

.vh-50 {
    height: 50vh;
}

.vh-75 {
    height: 75vh;
}

.border-dashed {
    border-style: dashed !important;
}

.color-nav {
    background-color: #0f0f0f;
    padding-top: 1px;
    transition: .7s ease all;
    height: 5rem;
}

.footer-category {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: .1rem;
}

.nav-link {
    color: #d9d9d9;
    font-size: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .1rem;
}
.nav-link:hover {
    color: #ffaa33;
}

.btn-primary {
    --bs-btn-color: #f5f5f5;
    --bs-btn-bg: #ff7f00;
    --bs-btn-border-color: #ff7f00;
    --bs-btn-hover-color: #f5f5f5;
    --bs-btn-hover-bg: #ffaa33;
    --bs-btn-hover-border-color: #ffaa33;
}

.btn-outline-primary {
    --bs-btn-color: #f5f5f5;
    --bs-btn-border-color: #ff7f00;
    --bs-btn-hover-color: #f5f5f5;
    --bs-btn-hover-bg: #ffaa33;
    --bs-btn-hover-border-color: #ffaa33;
}
